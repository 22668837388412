import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"

const FAQ = () => {
  return (
    <Layout>
      <SEO title="FAQ" />
      <div className="container flex flex-column ph4 pv2-ns pv0 ph0-ns">
        <h1 class="f2 lh-solid">FAQ</h1>
        <div class="mv1 mv3-ns">
          <h2 class="f3 lh-title">What’s Vanity? What can I do with it?</h2>
          <p class="lh-copy">
            Vanity is your digital skincare, makeup and overall beauty
            organization tool! The app is designed to create a digital inventory
            of your beauty items so you can quickly search for them and easily
            see what you own. No more buying duplicate shades because it was at
            the back of the drawer and you forgot about them (ugh!).
          </p>
          <div class="seperator light-gray mv4-ns"></div>
          <h2 class="f3 lh-title">How do I add a product?</h2>
          <p class="lh-copy">
            Tap on the + icon at the bottom of the screen. Try searching for the
            product using the search bar at the top, or tap on ‘Add manually’ if
            you can’t find the exact thing you’re looking for. Besides the name,
            brand, category, and photos, you can add the finish, price, color
            family, a rating, and your personal notes. Don’t forget to tap on
            ‘Add Item’ to add it to your Vanity.
          </p>
          <div class="seperator light-gray mv4-ns"></div>
          <h2 class="f3 lh-title">How do I edit/delete a product?</h2>
          <p class="lh-copy">
            Tap on the product you want to edit/delete, then tap on the pencil
            icon at the top right. You can edit the details on the ‘Edit Item’
            screen or delete it from your Vanity by tapping ‘Delete Item’ at the
            bottom of the screen.
          </p>
          <div class="seperator light-gray mv4-ns"></div>
          <h2 class="f3 lh-title">What’s a ‘Look’? How do I add one?</h2>
          <p class="lh-copy">
            Vanity’s Looks feature is the place for the looks you created and
            want to remember. Whether it’s your signature daily look, or a
            creative, bold look that looked amazing, you can add photos of it by
            tapping on the eye icon on the bottom of the screen, then tapping on
            ‘Add’.
          </p>
          <div class="seperator light-gray mv4-ns"></div>
          <h2 class="f3 lh-title">How do I log out?</h2>
          <p class="lh-copy">
            Tap on the profile icon at the bottom of the screen (rightmost),
            then tap on ‘Sign Out’. We’ll keep your digital inventory safe until
            you come back.
          </p>
          <div class="seperator light-gray mv4-ns"></div>
          <h2 class="f3 lh-title">How do I contact the support team?</h2>
          <p class="lh-copy">
            Just email us at{" "}
            <a href="mailto:support@getvanity.co">support@getvanity.co</a>
            &nbsp;and we’ll help!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default FAQ
